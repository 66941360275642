<template>
    <div>

        <div class="breadcrumb">
            <p>
                <img src="@/assets/img/icon-dropdown.svg">
            </p>
            <router-link :to="{ name: 'events' }">EVENTS</router-link>
            <p class="spacer">-</p>
            <p>UPCOMING EVENTS</p>
        </div>

        <div style="margin-top: 40px" class="info-events">
            <h2>UPCOMING EVENTS</h2>
        </div>

        
        <div v-if="this.isFetching == false">
            <div class="home-events events-upcoming">
                <div class="wrap">
                    <div class="events-feed">
                        <div class="event" v-for="(entry, item) in this.futureEntriesFetched" v-bind:key="'A' + item">
                            <div class="event-thumbnail">
                                <img v-if="entry.thumbnail[0]" :src="entry.thumbnail[0].url"  @click="goToEvent(entry.slug, entry.id)">
                            </div>

                            <h2 class="event-title">{{entry.title}}</h2>
                            <h2 class="event-date">{{formatDate( entry.eventDate, entry.eventStartTime)}}</h2>
                            <p class="event-desc">{{entry.postSummary}}</p>

                            <a @click="goToEvent(entry.slug)">view upcoming event</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="lds-ring">
            <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
        </div>

        <div class="breadcrumb">
            <p>
                <img src="@/assets/img/icon-dropdown.svg">
            </p>
            <router-link :to="{ name: 'events' }">BACK TO ALL EVENTS</router-link>
        </div>
    </div>
</template>
<script>

import gql from 'graphql-tag';
import moment from 'moment-timezone';
import $ from 'jquery';

export default {
    name: "Events",
    components: {
    },

    data() {
        return {
            isFetching: true,
            isRefreshing: false,
            pastEntriesFetched: [],
            futureEntriesFetched: [],
            liveEntriesFetched: [],
        }
    },

    mounted() {
        console.log("events mounted");

        var query = this.$apollo.queries;
        query.entries.skip = false;

        setInterval(function() {
            console.log("Query Refresh");
            query.entries.skip = false;
            query.entries.refresh()
        }, 60 * 1000);

        $('.wrapper').attr('style', '');
        $('.wrapper').css('position', 'unset');
    },

    methods: {
        formatDate(date, start) {
            date    = moment(date).format('L');
            start   = moment(start).format('HH:mm');

            var displayDate = moment(date + ' ' + start);

            return moment(displayDate).format('ddd, MMMM DD, HH:mm');
        },

        goToEvent(slug, id) {
            this.$router.push({name:'event', params:{slug:slug, eventId: id}})
        },
    },

    apollo: {
        entries: {
            client: 'craft',
            query: gql`query {
                entries {
                    ... on videoConferences_videoConferences_Entry {
                        id
                        slug
                        title
                        postSummary
                        imageBanner {
                            width
                            url
                        }
                        thumbnail {
                            width
                            url @transform (width: 1200, immediately: true)
                        }
                        eventDate
                        eventStartTime
                        eventEndTime
                    }
                }
            }`,

            // pollInterval: 60000, //ms
            fetchPolicy: 'network-only',

            skip () {
                console.log("skipping");
                return this.skipQuery
            },

            result ({ data, loading }) {
                console.log("Results");

                if (!loading) {
                    var entriesArray    = [];
                    var pastEntries     = [];
                    var futureEntries   = [];
                    var liveEntries     = [];

                    // Filter out any empty entries
                    data.entries.forEach(entry => {
                        if (entry.id) {
                            entriesArray.push(entry);
                        }
                    });

                    entriesArray.forEach(entry => {
                        var start   = entry.eventStartTime;
                        var end     = entry.eventEndTime;
                        var date    = entry.eventDate;

                        console.log("---------------------------------");
                        console.log("-----------EVENT START-----------");
                        console.log("---------------------------------");
                        console.log("RAW START TIME");
                        console.log(start);
                        console.log(date);

                        start   = moment.utc(start).format('HH:mm');
                        end     = moment(end).format('HH:mm');
                        date    = moment.utc(date).format('L');

                        console.log(date);

                        console.log("MOMENT START TIME");
                        console.log(start);
                        console.log("MOMENT START TIME TZ");
                        console.log(moment(start).tz("Europe/London"));

                        var startDate   = moment(date + ' ' + start, 'MM/DD/YYYY HH:mm');
                        var endDate     = moment(date + ' ' + end, 'MM/DD/YYYY HH:mm');

                        console.log("MOMENT STARTDATE TIME");
                        console.log(startDate);

                        console.log("UTC TIME");
                        var timeUTC = moment.tz("Europe/London").format('MM/DD/YYYY HH:mm');
                        console.log(timeUTC);

                        // Remmove html tags from description
                        entry.postSummary = entry.postSummary.replace(/<(?!a\s*\/?)[^>]+>/g, '')

                        if (moment(endDate) < moment(timeUTC)) {
                            console.log("Past event");

                            pastEntries.push(entry);
                        } 
                        
                        else if (moment(startDate) > moment(timeUTC)) {
                            console.log("Future event");

                            futureEntries.push(entry);
                        } 
                        
                        else if (moment(startDate) < moment(timeUTC) && moment(endDate) > moment(timeUTC)) {
                            console.log("Live event");

                            liveEntries.push(entry);
                        }

                        console.log("---------------------------------");
                        console.log("------------EVENT END------------");
                        console.log("---------------------------------");
                    });

                    this.isFetching = false;
                    this.$apollo.queries.entries.skip = true;

                    this.pastEntriesFetched     = pastEntries;
                    this.futureEntriesFetched   = futureEntries;
                    this.liveEntriesFetched     = liveEntries;
                }
            },
        }
    }
}
</script>